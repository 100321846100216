import React from "react"

export default function () {
  return (
    <small>
      <a
        href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet"
        target="_blank"
      >
        Github's markdown cheat sheet
      </a>
    </small>
  )
}
